/* eslint-disable react-hooks/exhaustive-deps */
import {useLayout} from '../../core'

export function HeaderWrapper() {
  const {config} = useLayout()
  if (!config.app?.header?.display) {
    return null
  }

  return <></>
}
