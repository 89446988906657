/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {PageTitle} from '../../../_metronic/layout/core'
import axios from 'axios'
import Avatar from 'react-avatar'
import {toast} from 'react-toastify'

type TypeOptions = 'info' | 'success' | 'warning' | 'error' | 'default'

type UserData = {
  appUserId: string
  fullName: string
  email: string
}

type AttendanceData = {
  loggedInUsers: UserData[]
  breakInUsers: UserData[]
}

type Response = {
  error: boolean
  data: {
    loggedInUsers: UserData[]
    breakInUsers: UserData[]
    message: string
  }
}

const showToast = (message: string, type: TypeOptions) => {
  toast(message, {
    position: 'top-right',
    autoClose: 3500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'colored',
    type: type,
    toastId: message,
  })
}

const UserAvatar: FC<{name: string}> = ({name}) => {
  return (
    <div className='d-flex align-items-center'>
      <div className='symbol symbol-circle symbol-50px overflow-hidden me-3'>
        <a href='#'>
          <div className='symbol-label'>
            <Avatar name={name} alt={name} size={'40px'} round={true} />
          </div>
        </a>
      </div>
    </div>
  )
}

const DashboardPage: FC = () => {
  const [attendanceData, setAttendanceData] = useState<AttendanceData>({
    loggedInUsers: [],
    breakInUsers: [],
  })

  // eslint-disable-next-line
  const getEmployeeDetails = async () => {
    try {
      const url =
        'https://api.attendance.workforce.nourishgenie.in/v1/agent/activity/get-users-working-status'
      const request = await axios.post(url)
      const response: Response = request.data
      if (!response.error) {
        setAttendanceData(() => {
          return {
            breakInUsers: response.data.breakInUsers,
            loggedInUsers: response.data.loggedInUsers,
          }
        })
      }
    } catch (error: any) {
      showToast(error?.message, 'error')
    }
  }

  useEffect(() => {
    getEmployeeDetails()
  }, [])
  return (
    <>
      <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
        <div className='col-lg-4 flex-column flex-lg-row-auto mb-10 mb-lg-0'>
          <div className='card card-flush'>
            <div className='card-header pt-7' id='kt_chat_contacts_header'>
              <h1>Logged In Users</h1>
            </div>
            <div className='card-body pt-0' id='kt_chat_contacts_body'>
              {attendanceData.loggedInUsers.map((user) => {
                return (
                  <div
                    key={user.appUserId}
                    className='scroll-y me-n5 p-0 h-200px h-lg-auto'
                    data-kt-scroll='true'
                    data-kt-scroll-activate='{default: false, lg: true}'
                    data-kt-scroll-max-height='auto'
                    data-kt-scroll-dependencies='#kt_header, #kt_toolbar, #kt_footer, #kt_chat_contacts_header'
                    data-kt-scroll-wrappers='#kt_content, #kt_chat_contacts_body'
                    data-kt-scroll-offset='0px'
                  >
                    <div className='d-flex flex-stack py-4'>
                      <div className='d-flex align-items-center'>
                        <UserAvatar name={user.fullName} />
                        <div className='ms-5'>
                          <a
                            href='#'
                            className='fs-5 fw-bolder text-gray-900 text-hover-primary mb-2'
                          >
                            {user.fullName}
                          </a>
                          <div className='fw-bold text-gray-400'>{user.email}</div>
                        </div>
                      </div>
                    </div>
                    <div className='separator separator-dashed d-none'></div>
                  </div>
                )
              })}
              {attendanceData.loggedInUsers.length === 0 && (
                <>
                  {' '}
                  <div className='text-center fw-bold'>No Records</div>
                </>
              )}
            </div>
          </div>
        </div>
        <div className='col-lg-4 flex-column flex-lg-row-auto mb-10 mb-lg-0'>
          <div className='card card-flush'>
            <div className='card-header pt-7' id='kt_chat_contacts_header'>
              <h1>Break In Users</h1>
            </div>
            <div className='card-body pt-0' id='kt_chat_contacts_body'>
              {attendanceData.breakInUsers.map((user) => {
                return (
                  <div
                    key={user.appUserId}
                    className='scroll-y me-n5 pe-5 h-200px h-lg-auto'
                    data-kt-scroll='true'
                    data-kt-scroll-activate='{default: false, lg: true}'
                    data-kt-scroll-max-height='auto'
                    data-kt-scroll-dependencies='#kt_header, #kt_toolbar, #kt_footer, #kt_chat_contacts_header'
                    data-kt-scroll-wrappers='#kt_content, #kt_chat_contacts_body'
                    data-kt-scroll-offset='0px'
                  >
                    <div className='d-flex flex-stack py-4'>
                      <div className='d-flex align-items-center'>
                        <UserAvatar name={user.fullName} />

                        <div className='ms-5'>
                          <a
                            href='#'
                            className='fs-5 fw-bolder text-gray-900 text-hover-primary mb-2'
                          >
                            {user.fullName}
                          </a>
                          <div className='fw-bold text-gray-400'>{user.email}</div>
                        </div>
                      </div>
                    </div>

                    <div className='separator separator-dashed d-none'></div>
                  </div>
                )
              })}
              {attendanceData.breakInUsers.length === 0 && (
                <>
                  {' '}
                  <div className='text-center fw-bold'>No Records</div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const DashboardWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>Attendance Dashboard</PageTitle>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
